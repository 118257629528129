import React from 'react';
import { Col, Form } from 'react-bootstrap';
import UploadIcon from '../../icons/UploadIcon';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
      FormGroupBody,
      FormGroupInput,
      FormGroupSection,
      FormGroupUpload,
      FormImageContainer,
      FormImageGallery,
      FormImageRemove
} from '../../../styles/components/forms/Upload';

const MultipleImage = ({
      title,
      required,
      images,
      websiteImages,
      setImages,
      classes,
      setImageFiles,
      setProductImages,
      grid,
      imageType
}) => {
      const token = JSON.parse(localStorage.getItem('token'));

      const handleImageChange = (e) => {
            const fileList = e.target.files;
            const imageArray = Array.from(fileList).map((file) =>
                  URL.createObjectURL(file)
            );
            const fileArray = Array.from(fileList);
            setImageFiles((prevImageFiles) => [
                  ...prevImageFiles,
                  ...fileArray
            ]);
            setImages((prevImages) => [...prevImages, ...imageArray]);
      };

      const handleRemoveImage = (index) => {
            setImages((prevImages) =>
                  prevImages.filter((image, i) => i !== index)
            );
      };

      const handleRemoveProductImage = async (event, image, index) => {
            event.preventDefault();

            setProductImages((websiteImages) =>
                  websiteImages.filter((item, i) => i !== index)
            );

            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/images/${image.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Col lg={classes ? classes : 4}>
                        <Form.Group>
                              <FormGroupBody>
                                    <Form.Label>
                                          {title}{' '}
                                          {required && (
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          )}
                                    </Form.Label>
                                    <FormGroupSection>
                                          <FormGroupUpload
                                                role="button"
                                                tabIndex={0}
                                          >
                                                <FormGroupInput
                                                      multiple
                                                      onChange={
                                                            handleImageChange
                                                      }
                                                      type="file"
                                                />
                                                <UploadIcon />
                                                <p>
                                                      <span className="text-accent font-semibold">
                                                            Upload an image or
                                                            drag and drop{' '}
                                                      </span>

                                                      <br />
                                                      <span>
                                                            {imageType
                                                                  ? imageType
                                                                  : 'PNG, JPG'}
                                                      </span>
                                                </p>
                                          </FormGroupUpload>
                                    </FormGroupSection>

                                    <FormImageGallery
                                          style={{
                                                gridTemplateColumns:
                                                      grid &&
                                                      `repeat(${grid}, 1fr)`
                                          }}
                                    >
                                          {imageType
                                                ? images.map((image, index) => (
                                                        <FormImageContainer
                                                              className="border d-flex align-items-center px-2"
                                                              key={index}
                                                              style={{
                                                                    height: '44px'
                                                              }}
                                                        >
                                                              <div className="d-flex align-items-center">
                                                                    <i className="bx bxs-file fs-4"></i>
                                                                    {image.name}
                                                              </div>
                                                              <FormImageRemove
                                                                    onClick={() =>
                                                                          handleRemoveImage(
                                                                                index
                                                                          )
                                                                    }
                                                              >
                                                                    Remove
                                                              </FormImageRemove>
                                                        </FormImageContainer>
                                                  ))
                                                : images.map((image, index) => (
                                                        <FormImageContainer
                                                              key={index}
                                                        >
                                                              <img
                                                                    src={image}
                                                                    alt={`Image ${
                                                                          index +
                                                                          1
                                                                    }`}
                                                              />
                                                              <FormImageRemove
                                                                    onClick={() =>
                                                                          handleRemoveImage(
                                                                                index
                                                                          )
                                                                    }
                                                                    type="button"
                                                              >
                                                                    Remove
                                                              </FormImageRemove>
                                                        </FormImageContainer>
                                                  ))}

                                          {websiteImages &&
                                                websiteImages.map(
                                                      (image, index) => (
                                                            <FormImageContainer
                                                                  key={index}
                                                            >
                                                                  <img
                                                                        src={
                                                                              image.image
                                                                        }
                                                                        alt={`Image ${
                                                                              index +
                                                                              1
                                                                        }`}
                                                                  />
                                                                  <FormImageRemove
                                                                        onClick={(
                                                                              event
                                                                        ) =>
                                                                              handleRemoveProductImage(
                                                                                    event,
                                                                                    image,
                                                                                    index
                                                                              )
                                                                        }
                                                                  >
                                                                        Remove
                                                                  </FormImageRemove>
                                                            </FormImageContainer>
                                                      )
                                                )}
                                    </FormImageGallery>
                              </FormGroupBody>
                        </Form.Group>
                  </Col>
            </>
      );
};

export default MultipleImage;
